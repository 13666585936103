import {Sprite} from "react-pixi-fiber"
import * as PIXI from "pixi.js"
// import Easing from 'easing';
// @ts-ignore
import nisse from '../../assets/nisse.png';
import {useDispatch} from "react-redux";
import Animated from "animated";
import Easing from "animated/lib/Easing";
import {useEffect, useRef, useState} from "react";
import {endMove, finish, playerStates, setDescription, wire} from "../GameState/player/playerSlice";
import SpineSprite from "../SpineSprite/SpineSprite";
import MultiSpineSprite from "../MultiSpineSprite/MultiSpineSprite";

const AnimatedSprite = Animated.createAnimatedComponent(Sprite);
const AnimatedSpineSprite = Animated.createAnimatedComponent(SpineSprite);

const Player = ({state, x, y, nextX, nextY, tree, socket, startWire}) => {
  const dispatch = useDispatch();
  const [posX] = useState(new Animated.Value(x * 256));
  const [posY] = useState(new Animated.Value(y * 256));

  useEffect(() => {
    if (nextX != x) {
      Animated.timing(posX, {
        toValue: nextX * 256,
        duration: 500,
        easing: Easing.linear(Easing.ease),
        // easing: Easing.bounce,
      }).start(() => {
        dispatch(endMove());
      });
    }
    if (nextY != y) {
      Animated.timing(posY, {
        toValue: nextY * 256,
        duration: 500,
        // easing: Easing.bounce,
      }).start(() => {
        dispatch(endMove());
      });
    }
  }, [x, y, nextX, nextY])

  useEffect( () => {
    if (`${x + 1}-${y}` === tree || `${x - 1}-${y}` === tree || `${x}-${y + 1}` === tree || `${x}-${y - 1}` === tree) {
      if(!startWire) {
        dispatch(wire(tree));
        dispatch(wire(`${x}-${y}`));
        dispatch(setDescription('You found the tree. Now wire it to the power source!'));
      }
    }
    if (`${x + 1}-${y}` === socket || `${x - 1}-${y}` === socket || `${x}-${y + 1}` === socket || `${x}-${y - 1}` === socket) {
      if(startWire) {
        dispatch(finish(''))
      } else {
        dispatch(setDescription('You found the socket. But you have to find the tree first!'))
      }
    }
  }, [x, y])

  // @ts-ignore
  const base_url = import.meta.env.VITE_BACKEND_BASE_URL;

  return (
    <MultiSpineSprite
      name="nisse"
      x={posX}
      y={posY}
      animation={state}
      spineData={[
        {
          name: "nisse-front",
          src: `${base_url}/resources/app/tilemap/wichtel-front.json`,
          animation: "idle",
          width: 256,
          height: 388.04,
        },
        {
          name: "nisse-side",
          src: `${base_url}/resources/app/tilemap/wichtel-side.json`,
          animation: "walking",
          width: 500,
          height: 388.04,
        },
        {
          name: "nisse-back",
          src: `${base_url}/resources/app/tilemap/wichtel-back.json`,
          animation: "walking",
          width: 500,
          height: 388.04,
        },
      ]}
      animationData={{
        "MOVING_RIGHT": {
          "sprite": "nisse-side",
          "animation": "walking",
          width: 285,
          height: 368,
        },
        "MOVING_LEFT": {
          "sprite": "nisse-side",
          "animation": "walking",
          "mirror": true,
          width: 285,
          height: 368,
        },
        "MOVING_UP": {
          "sprite": "nisse-back",
          "animation": "walking",
          width: 256,
          height: 388,
        },
        "MOVING_DOWN": {
          "sprite": "nisse-front",
          "animation": "walking",
          width: 256,
          height: 388.04,
        },
        "BLOCKED_RIGHT": {
          "sprite": "nisse-side",
          "animation": "idle",
          width: 285,
          height: 368,
        },
        "BLOCKED_LEFT": {
          "sprite": "nisse-side",
          "animation": "idle",
          "mirror": true,
          width: 285,
          height: 368,
        },
        "BLOCKED_UP": {
          "sprite": "nisse-back",
          "animation": "idle",
          width: 256,
          height: 388,
        },
        "BLOCKED_DOWN": {
          "sprite": "nisse-front",
          "animation": "idle",
          width: 256,
          height: 388.04,
        },
        "IDLE": {
          "sprite": "nisse-front",
          "animation": "idle",
          width: 256,
          height: 388.04,
        }
      }}
    />
  );
}
export default Player;
