import {useEffect, useState} from "react";
import {Container} from "react-pixi-fiber";
import TilemapLayer from "./TilemapLayer";
import {Rectangle, Texture} from "pixi.js";

export class Tileset {
  src: string;
  baseTexture: Texture;

  constructor(data) {
    this.src = data.src;
    this.baseTexture = Texture.from(data.src);
  }

  getTexture(x, y) {
    const rect = new Rectangle(x * 256, y * 256, 256, 256);
    return new Texture(this.baseTexture.baseTexture, rect);
  }
}

const Tilemap = ({src, activeMap='Map_1'}) => {
  const [layers, setLayers] = useState([]);

  useEffect(() => {
    fetch(src).then((response) => {
      response.json().then((data) => {
        const tilesets = {};
        Object.keys(data.tileSets).forEach((key) => {
          tilesets[key] = new Tileset(data.tileSets[key]);
        });

        const newLayers = [];
        for (const layer of data.maps[activeMap].layers) {
          // console.log("XXX", layer);
          newLayers.push(<TilemapLayer {...layer} tilesets={tilesets} key={layer.name}/>);
        }
        setLayers(newLayers);
      });
    });
  }, [src]);
  return <Container>
    {layers}
  </Container>
};

export default Tilemap;
