import styled from "styled-components";

export const Headline = styled.h1`
  color: var(--color-primary);
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
`;

export const TextLink = styled.a`
`;
