import Animated from "animated";
import {useEffect, useState} from "react";
import {Container, Sprite} from "react-pixi-fiber";
import {Loader} from "pixi.js";
import SpineSpriteComponent from "../SpineSprite/SpineSpriteComponent";

const AnimatedContainer = Animated.createAnimatedComponent(Container);

const MultiSpineSprite = ({name, x, y, animation, spineData = [], animationData = {}}) => {
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  let width = 256;
  let height = 388.04;


  useEffect(() => {
    if (data) {
      // Already loaded
      return;
    }
    const loader = Loader.shared;
    spineData.forEach((conf) => {
      if(conf.src)
        loader.add(conf.name, conf.src);
    });
    loader
      .load(res => {
        const data = {};
        spineData.forEach((conf) => {
          data[conf.name] = res.resources[conf.name].spineData;
        });
        console.log("LOADED", data);
        setData(data);
        setLoaded(true);
      });
  }, []);

  return <AnimatedContainer x={x} y={y} width={width} height={height}>
    {loaded ? (
      Object.keys(animationData).map((animationKey, index) => {
        const conf = animationData[animationKey];
        return (
          <SpineSpriteComponent
            key={animationKey}
            data={data[conf.sprite]}
            x={width / 2}
            y={height / 2}
            width={conf.width}
            height={conf.height}
            mirror={conf.mirror}
            animation={conf.animation}
            alpha={animation === animationKey ? 1 : 0}
          />
        )
      })

    ) : null}
  </AnimatedContainer>
};

export default MultiSpineSprite;