import TileMapEditor from 'tilemap-editor';
import 'tilemap-editor/src/styles.css';
import {useEffect} from "react";
import {useParams} from "react-router-dom";

const Editor = () => {

  const { level } = useParams();

  const tileSetImages = [
    {
      src:"http://localhost/nkt-julenisse/public/resources/app/tilemap/tilemap.png",
      name: "Julenisse",
      tileSize: 256,
    },
  ];

  // @ts-ignore
  const base_url = import.meta.env.VITE_BACKEND_BASE_URL;

  useEffect(() => {
    fetch(`${base_url}/levels/load/${level}`).then((response) => { // http://localhost/nkt-julenisse/public/resources/app/tilemap/tilemap.json?2
      response.json().then((data) => {
        TileMapEditor.init("tilemap-editor", {
          tileSize: 256,
          mapWidth: 10,
          mapHeight: 10,
          tileMapData: data,
          tileSetImages,
          tileSetLoaders: {
            // fromUrl: {
            //   name: "Any url", // name is required and used for the loader's title in the select menu
            //   prompt: (setSrc) => { // Pass prompt ot onSelectImage. Prompt lets you do anything without asking the user to select a file
            //     const fileUrl = window.prompt("What is the url of the tileset?", "https://i.imgur.com/ztwPZOI.png");
            //     if (fileUrl !== null) setSrc(fileUrl)
            //   }
            // },
          },
          tileMapExporters: {},
          tileMapImporters: {},
          onApply: {
            onClick: ({flattenedData, maps, tileSets, activeMap}) => {
              const data = JSON.stringify(maps)
              const formData = new FormData();
              formData.append('data', data);
              fetch(`${base_url}/levels/save/${level}`, {
                method: "POST",
                body: formData,
              }).then((response) => {
                // alert("Ok");
              });
            },
            buttonText: "Copy Kb to clip", // controls the apply button's text
          },
        });
        document.getElementById("zoomOut").click();
      });
    });
  });
  return <div id="tilemap-editor" style={{height: '100%'}}>Editor</div>
};

export default Editor;
