import styled from "styled-components";
import useSWR from 'swr';
import Cookies from 'js-cookie';
import QuizBackground from "./QuizBackground/QuizBackground";
import House, {HouseStates} from "./House/House";
import {Route, Routes, useNavigate} from "react-router-dom";
import QuizDay from "./QuizDay/QuizDay";
import Modal from "./Modal/Modal";
import {useEffect, useState} from "react";
import IntroScreen from "./IntroScreen/IntroScreen";
import media from "../utilities/media";

const QuizStyled = styled.div`  
`;

const QuizMenu = styled.div`
  position: fixed;
  top: 10px;
  left: 0;
  z-index: 100;
  
  ${media.moreThan("lg")} {
    position: absolute;
    top: 40px;
    left: 200px;
  }
`;

const QuizMenuLink = styled.div`
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  
  ${media.moreThan("lg")} {
    color: var(--color-primary);   
  }
`;

const Quiz = ({ transactionId }) => {
  const [showIntro, setShowIntro] = useState(!Cookies.get('intro-shown'));
  const [score, setScore] = useState(null);

  const fetcher = (url) => fetch(url).then((res) => res.json());

  // @ts-ignore
  const base_url = import.meta.env.VITE_BACKEND_BASE_URL;
  const { data: questions } = useSWR(`${base_url}/questions/load`, fetcher);
  useEffect(() => {
    fetcher(`${base_url}/questions/score/${transactionId}`).then((data) => {
      setScore(data);
    });
  }, [transactionId]);

  const day = (new Date().getTime() - Date.parse("2022-12-01 00:00")) / 1000 / 60 / 60 / 24 + 1;

  return <QuizStyled>
    <Modal show={showIntro}>
      <IntroScreen onClose={() => {
        setShowIntro(false);
        Cookies.set(`intro-shown`, 1);
      }}/>
    </Modal>
    <Routes>
      <Route index element={
        <>
          <QuizMenu>
            <QuizMenuLink onClick={() => setShowIntro(true)}>Show Info</QuizMenuLink>
          </QuizMenu>
          <QuizBackground>
            {questions && questions.map((question) => {
              let state = question.Number > day ? HouseStates.disabled : HouseStates.active;
              state = score && score.quiz[question.Number] === question.CorrectAnswer ? HouseStates.solved : state;

              return (
                <House
                  key={question.Number}
                  posX={`${question.PosX}%`}
                  posY={`${question.PosY}%`}
                  mobilePosX={`${question.MobilePosX}%`}
                  mobilePosY={`${question.MobilePosY}%`}
                  variant={`house${question.Variant}`}
                  state={state}
                  gameSolved={score && score.game[question.Number]}
                  number={question.Number}
                  onClick={() => location.href=`/quiz/${question.Number}`}
                />
              );
            })}
          </QuizBackground>
        </>
        }/>
      <Route path=":day" element={<QuizDay data={questions} score={score} transactionId={transactionId}/>}/>
    </Routes>

  </QuizStyled>;
}

export default Quiz;
