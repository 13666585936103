import styled, { keyframes, css } from "styled-components";

import { ReactComponent as Pillar } from "./WindTurbine-Pillar.svg";
import { ReactComponent as Wings } from "./WindTurbine-Wings.svg"

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const WindTurbineStyled = styled.div`
  position: relative;
  width:100%;
  padding-top: 50%;
`;

const Rotor = styled.div`
  ${props => props.rotation && css`
    animation: ${rotate} 2s linear infinite;
  `}
  position: absolute;
  top:0;
  left:0;
  width:100%;
`;

const WindTurbine = ({ rotation = false }) => {
  return (
    <WindTurbineStyled>
      <Pillar/>
      <Rotor rotation={rotation}>
        <Wings/>
      </Rotor>
    </WindTurbineStyled>
  )
}

export default WindTurbine;