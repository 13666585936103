import {Container} from "react-pixi-fiber";
import {useEffect, useState} from "react";
import {endMove} from "../GameState/player/playerSlice";
import Animated from "animated";
import Easing from 'easing';

const AnimatedContainer = Animated.createAnimatedComponent(Container);

const Viewport = ({ children, scale, x=0, y=0, nextX= 0, nextY= 0, mobile }) => {
  const xDiff = mobile ? 2 : 3;

  const [posX] = useState(new Animated.Value(-(x - xDiff) * 256 * scale));
  const [posY] = useState(new Animated.Value(-(y - 2) * 256 * scale));

  // x={-(playerPosition.x -2) * 256 * scale} y={-(playerPosition.y -2) * 256 * scale}

  useEffect(() => {
    if (nextX != x) {
      Animated.timing(posX, {
        toValue: -(nextX - xDiff) * 256 * scale,
        duration: 1000,
        easing: Easing.bounce,
      }).start(() => {
        // dispatch(endMove());
      });
    }
    if (nextY != y) {
      Animated.timing(posY, {
        toValue: -(nextY - 2) * 256 * scale,
        duration: 1000,
        easing: Easing.bounce,
      }).start(() => {
        // dispatch(endMove());
      });
    }

  }, [x, y, nextX, nextY])

  // x={-(playerPosition.x -2) * 256 * scale}
  // y={-(playerPosition.y -2) * 256 * scale}
  return <AnimatedContainer scale={scale} x={posX} y={posY}>
    {children}
  </AnimatedContainer>
}

export default Viewport;
