import GameStage from "./GameStage/GameStage";
import Grid from "./Grid/Grid";
import Player from "./Player/Player";
import KeyboardController from "./KeyboardController/KeyboardController";
import {Provider, useDispatch, useSelector, useStore} from "react-redux";
import {selectPosition} from "./GameState/player/playerSlice";
import store from "../store";
import useWindowDimensions from "../utilities/useWindowDimensions";
import {Tileset} from "./Tilemap/Tilemap";
import Viewport from "./Viewport/Viewport";
import TouchController from "./TouchController/TouchController";
import {useParams} from "react-router-dom";
import TilemapLayer from "./Tilemap/TilemapLayer";
import {useEffect, useState} from "react";
import {loadLevel} from "./GameState/player/playerSlice";
import Wire from "./Wire/Wire";
import Modal from "../quiz/Modal/Modal";
import ThankYouGameForm from "./ThankYouGameForm/ThankYouGameForm";
import QuizBackground from "../quiz/QuizBackground/QuizBackground";
import Box from "./Box/Box";
import Hint from "./Hint/Hint";
import styled from "styled-components";
import media from "../utilities/media";

const GameMain = styled.div`
  margin: 0 auto;
`;
const GameSize = styled.div`
  transform: scale(0.5);
  transform-origin: top left;
  padding-top: 25%;
  
  ${media.moreThan("lg")} {
    padding-top: 0;
  }
`;

const Game = ({ transactionId }) => {

  const {level} = useParams();
  const playerPosition = useSelector(selectPosition)

  // console.log('playerPosition', playerPosition)

  const {width, height} = useWindowDimensions();
  const dispatch = useDispatch();
  const [layers, setLayers] = useState(null);
  const [tilesets, setTilesets] = useState(null);

  const maxSize = width > 800 ? Math.min(width, (height - 152) / 5 * 7) : Math.min(width, (height - 152));

  // const scale = maxSize / 5 / 256 * 2;
  const scale = width > 800 ? ((maxSize / 7) / 256 * 2) : ((maxSize / 5) / 256 * 2);
  const mobile = width < 800;
  // console.log("Foo", maxSize, scale);

  // @ts-ignore
  const base_url = import.meta.env.VITE_BACKEND_BASE_URL;

  // Load tilemap, parse special objects to game state
  const src = `${base_url}/levels/load/${level}`;
  useEffect(() => {
    fetch(src).then((response) => {
      response.json().then((data) => {
        const tilesets = {};
        const description = data.description;
        Object.keys(data.tileSets).forEach((key) => {
          tilesets[key] = new Tileset(data.tileSets[key]);
        });

        const newLayers = {};
        const walls = [];
        const ice = [];
        const boxes = [];
        const specials = {
          start: '0-0',
        };

        const mapWidth = data.maps['Map_1'].mapWidth;
        const mapHeight = data.maps['Map_1'].mapHeight;

        for (const layer of data.maps["Map_1"].layers) {
          if (layer.name === "floor") {
            Object.keys(layer.tiles).map((key, index) => {
              const object = layer.tiles[key];
              if (object.x === 1 && object.y === 1) {
                ice.push(key);
              }
            });
          }
          // if (layer.name === "objects") {
          //   Object.keys(layer.tiles).map((key, index) => {
          //     const object = layer.tiles[key];
          //   });
          // }
          if (layer.name === "walls") {
            Object.keys(layer.tiles).map((key, index) => {
              walls.push(key);
            });
          }
          if (layer.name === 'specials') {
            Object.keys(layer.tiles).map((key, index) => {
              const object = layer.tiles[key];

              // Start
              if (object.x === 0 && object.y === 0) {
                specials['start'] = key;
              }

              // Tree
              if (object.x === 1 && object.y === 0) {
                specials['tree'] = key;
              }

              // Socket
              if (object.x === 2 && object.y === 0) {
                specials['socket'] = key;
              }

              // Box
              if (object.x === 0 && object.y === 1) {
                boxes.push(key);
              }
            });
            continue; // Don't show specials layer
          }
          newLayers[layer.name] = <TilemapLayer {...layer} tilesets={tilesets} key={layer.name} width={mapWidth} height={mapHeight}/>;
        }
        dispatch(loadLevel({
          walls,
          specials,
          level,
          ice,
          boxes,
          transactionId,
          mapWidth,
          mapHeight,
          description,
        }));
        setLayers(newLayers);
        setTilesets(tilesets);
      });
    });
  }, [src]);

  return (
    <>
      <TouchController>
        <div style={{
          height: height - 152,
          overflow: 'hidden',
        }}>
          <QuizBackground>
            <GameMain style={{
              width: maxSize,
            }}>
              <GameSize>
                <KeyboardController/>
                {layers ? (
                  <GameStage size={maxSize * 2} mobile={mobile}>
                    <Provider store={store}>
                      <Viewport scale={scale} {...playerPosition} mobile={mobile}>
                        {/*<Grid/>*/}
                        {layers.floor}
                        <Wire fields={playerPosition.wired}/>
                        {layers.walls}
                        {layers.objects}
                        {playerPosition.boxes.map((box, index) => {
                          return (
                            <Box id={box.id} x={box.x} y={box.y} nextX={box.nextX} nextY={box.nextY} tileset={tilesets[2]} key={index}/>
                          );
                        })}
                        <Player {...playerPosition}/>
                      </Viewport>
                    </Provider>
                  </GameStage>
                ) : null}
              </GameSize>
              { playerPosition.description ? <Hint>{playerPosition.description}</Hint> : null }
            </GameMain>
          </QuizBackground>
        </div>
      </TouchController>
      <Modal show={playerPosition.finished}>
        <ThankYouGameForm/>
      </Modal>
    </>
  )
}

export default Game
