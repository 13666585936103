import {useCallback, useEffect, useRef} from "react";
import { Graphics } from "react-pixi-fiber";
import Srand from 'seeded-rand';

const Wire = ({ fields=[] }) => {
  const graphref = useRef(null);

  const draw = useCallback((g) => {
    // console.log('Drawing', fields);
    const rnd = new Srand(10);
    g.clear()
    g.lineStyle(10, 0x000000, 1)
    let lastX = -1;
    let lastY = -1;
    fields.forEach((field) => {
      const [x, y] = field.split('-').map(val => parseInt(val));
      // g.drawRect(x * 256, y * 256, 256, 256);
      g.beginFill(0x000000);
      // g.drawCircle( x * 256 + 128, y * 256 + 128, 10 )
      g.endFill();

      if(lastX != -1) {
        g.moveTo(lastX * 256 + 128, lastY * 256 + 128);
        // g.lineTo(x * 256 + 128, y * 256 + 128);
        g.bezierCurveTo(
          lastX * 256 + 128 + rnd.inRange(-32, 32), lastY * 256 + rnd.inRange(-32, 32),
          x * 256 + 128 + rnd.inRange(-32, 32), y * 256 + 256 + rnd.inRange(-32, 32),
          x * 256 + 128, y * 256 + 128
        )
      }

      lastX = x;
      lastY = y;
    })
  }, [fields])

  useEffect(() => {
    if (graphref.current) {
      draw(graphref.current)
    }
  }, [graphref, fields])

  return <Graphics ref={graphref} />
}

export default Wire;
