import styled from "styled-components";
import Button, {ButtonStatus, ButtonStyled} from "../../ui/Button/Button";
import {useEffect, useState} from "react";

const Text = styled.p`
  font-size: 13px;
  text-align: center;
  margin-bottom: 20px;
`;

const Question = styled.p`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
`;

const QuizFormStyled = styled.div`
`;

const ButtonContainer = styled.div`
  padding: 0 0 20px;
  width: 80%;
  margin: 0 auto;
`;

const ButtonItem = styled.div`
  padding: 10px 0;
`;

const QuizForm = ({ text, question, answers, correctAnswerId, onAnswer, selected}) => {
  const [displayAnswers, setDisplayAnswers] = useState(answers);

  useEffect(() => {
    setDisplayAnswers(answers.map((answer) => {
      if (!selected) {
        return answer;
      }
      const displayAnswer = {...answer};
      if (answer.id === correctAnswerId) {
        if (answer.id === selected) {
          displayAnswer.status = ButtonStatus.correct;
        } else {
          displayAnswer.status = ButtonStatus.correctDisabled;
        }
      } else {
        if (answer.id === selected) {
          displayAnswer.status = ButtonStatus.wrong;
        } else {
          displayAnswer.status = ButtonStatus.wrongDisabled;
        }
      }
      return displayAnswer;
    }));
  }, [answers, selected]);

  return (
    <QuizFormStyled>
      <Text>{text}</Text>
      <Question>{question}</Question>
      <ButtonContainer>
        {displayAnswers.map((answer, index) => (
          <ButtonItem key={answer.id}>
            <Button
              status={answer.status}
              number={!answer.status ? index + 1 : 0}
              onClick={() => {
                if(selected) {
                  return; // Not allowed to change answer
                }
                onAnswer(answer.id);
              }}>{answer.text}</Button>
          </ButtonItem>
        ))}
      </ButtonContainer>

    </QuizFormStyled>
  )
}

export default QuizForm;
