import {Stage} from "react-pixi-fiber";

const GameStage = ({ size, children, mobile }) => {
  const OPTIONS = {
    backgroundColor: 0x000000,
    antialias: true,
    width: size,
    height: mobile ? size : (size / 7 * 5),
    // resizeTo: document.getElementById("root"),
    // resolution: window.devicePixelRatio,
    autoDensity: true,
  }

  return <Stage options={OPTIONS}>
    {children}
  </Stage>
}

export default GameStage;
