import {Container, Sprite} from "react-pixi-fiber";

const TilemapLayer = ({tiles, tilesets, height, width}) => {
  const sprites = Object.keys(tiles).map((key, index) => {
    const [positionX, positionY] = key.split('-').map(Number);

    if (positionX > width-1 || positionY > height-1) {
      return null; // Skip out of map
    }
    // console.log(positionX, positionY, width, height);
    const {x, y, tilesetIdx, isFlippedX} = tiles[key];
    const tileset = tilesets[tilesetIdx];
    return <Sprite
      key={index}
      x = {positionX * 256}
      y = {positionY * 256}
      width = {258}
      height = {258}
      texture={tileset.getTexture(x, y)}
    />
  });
  return <Container>
    {sprites}
  </Container>
}

export default TilemapLayer;
