import { CustomPIXIComponent } from "react-pixi-fiber";
import * as PIXI from "pixi.js";
import {Spine} from "pixi-spine";

const TYPE = "SpineSprite";
export const behavior = {
  customDisplayObject: ({ data }) => {
    const instance = new Spine(data);
    instance.update(0);
    instance.autoUpdate = true;
    return instance;
  },
  customApplyProps: function(instance, oldProps, newProps) {
    const { x, y, width, height, alpha, mirror = false, animation, ...newPropsRest } = newProps;
    // const { x: oldX, y: oldY, width: oldWidth, height: oldHeight, ...oldPropsRest } = oldProps;
    // if (typeof oldProps !== "undefined") {
    //   instance.clear();
    // }
    // instance.setToSetupPose();

    // console.log("SET", animation, newProps.alpha, oldProps ? oldProps.alpha: null);

    instance.position.set(x, y);
    instance.width = width;
    instance.height = height;
    instance.alpha = alpha;
    if(mirror) {
      instance.scale.x = instance.scale.x * -1;
    }
    // instance.state.timeScale = 2.667;
    instance.state.timeScale = 4;
    instance.skeleton.setToSetupPose();
    instance.state.tracks = [];
    if(alpha == 1)
      instance.state.setAnimation(0, animation, true);

    // this.applyDisplayObjectProps(oldPropsRest, newPropsRest);
  },
  // customDidAttach
  // customWillDetach
};

export default CustomPIXIComponent(behavior, TYPE);
