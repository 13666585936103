import { configureStore } from '@reduxjs/toolkit'
import playerReducer from './game/GameState/player/playerSlice';

const store = configureStore({
  reducer: {
    player: playerReducer,
  }
})

export default store;
