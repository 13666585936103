import { ReactComponent as Mobile1_1 } from "./assets/Pfad_Mobile_01_01.svg";
import { ReactComponent as Mobile1_2 } from "./assets/Pfad_Mobile_01_02.svg";
import { ReactComponent as Mobile1_3 } from "./assets/Pfad_Mobile_01_03.svg";
import { ReactComponent as Desktop1_1 } from "./assets/Pfad_Web_01_01.svg";
import { ReactComponent as Desktop1_2 } from "./assets/Pfad_Web_01_02.svg";
import { ReactComponent as Desktop1_3 } from "./assets/Pfad_Web_01_03.svg";
import { ReactComponent as Mobile2_1 } from "./assets/Pfad_Mobile_02_01.svg";
import { ReactComponent as Mobile2_2 } from "./assets/Pfad_Mobile_02_02.svg";
import { ReactComponent as Mobile2_3 } from "./assets/Pfad_Mobile_02_03.svg";
import { ReactComponent as Desktop2_1 } from "./assets/Pfad_Web_02_01.svg";
import { ReactComponent as Desktop2_2 } from "./assets/Pfad_Web_02_02.svg";
import { ReactComponent as Desktop2_3 } from "./assets/Pfad_Web_02_03.svg";
import { ReactComponent as Mobile3_1 } from "./assets/Pfad_Mobile_03_01.svg";
import { ReactComponent as Mobile3_2 } from "./assets/Pfad_Mobile_03_02.svg";
import { ReactComponent as Mobile3_3 } from "./assets/Pfad_Mobile_03_03.svg";
import { ReactComponent as Desktop3_1 } from "./assets/Pfad_Web_03_01.svg";
import { ReactComponent as Desktop3_2 } from "./assets/Pfad_Web_03_02.svg";
import { ReactComponent as Desktop3_3 } from "./assets/Pfad_Web_03_03.svg";

const mazes = {
  1: [
    {
      Mobile: Mobile1_1,
      Desktop: Desktop1_1,
    },
    {
      Mobile: Mobile1_2,
      Desktop: Desktop1_2,
    },
    {
      Mobile: Mobile1_3,
      Desktop: Desktop1_3,
    }
  ],
  2: [
    {
      Mobile: Mobile2_1,
      Desktop: Desktop2_1,
    },
    {
      Mobile: Mobile2_2,
      Desktop: Desktop2_2,
    },
    {
      Mobile: Mobile2_3,
      Desktop: Desktop2_3,
    }
  ],
  3: [
    {
      Mobile: Mobile3_1,
      Desktop: Desktop3_1,
    },
    {
      Mobile: Mobile3_2,
      Desktop: Desktop3_2,
    },
    {
      Mobile: Mobile3_3,
      Desktop: Desktop3_3,
    }
  ]
}

export default mazes;
