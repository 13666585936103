import Cookies from "js-cookie";

export function create_UUID() {
  let dt = new Date().getTime();
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    // eslint-disable-next-line
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    // eslint-disable-next-line
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export const getTransactionId = () => {
  let id = Cookies.get(`game-id`) || "no-cookie";
  if (id === "no-cookie") {
    id = create_UUID();
    Cookies.set(`game-id`, id, { expires: 180 });
  }
  return id;
};

export const setTransactionId = (id: string) => {
  Cookies.set(`game-id`, id, { expires: 180 });
}
