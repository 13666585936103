import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  
  display: ${props => props.show ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
`;

const ModalWin = styled.div`
  background-color: #fff;
  padding: 40px 20px;
  width: 100%;
  max-width: 600px;
`;

const ModalContent = styled.div`
  max-width: 460px;
  margin:0 auto;
`;

const Modal = ({ children, show }) => {
  return (
    <ModalOverlay show={show}>
      <ModalWin>
        <ModalContent>
          {children}
        </ModalContent>
      </ModalWin>
    </ModalOverlay>
  );
}

export default Modal;
