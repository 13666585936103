import styled from "styled-components";
import Button from "../../ui/Button/Button";
import {useNavigate} from "react-router-dom";
import {Headline, Text} from "../../ui/Typography/Typography";
import {useState} from "react";
import useSWR from "swr";
import {setTransactionId} from "../../utilities/transactionid";
import {Simulate} from "react-dom/test-utils";
import submit = Simulate.submit;

const ThankYouFormStyled = styled.div`
`;

const SubmissionForm = styled.form`
  padding: 0;
  margin: 0 auto 20px;
`;

const ButtonItem = styled.div`
  padding: 10px 0;
  max-width: 300px;
  margin:0 auto;
`;

const Input = styled.input`
  border: 2px solid var(--color-grey);
  font-size: 16px;
  width: 100%;
  padding: 10px 20px;
`;

const Checkbox = styled.input`
`;

const CheckboxLabel = styled.label`
`;

const Link = styled.a`
  color: #1E326E;
  text-decoration: underline;
  
  &:visited, &:active, &:hover {
    color: #1E326E;
    text-decoration: underline;
  }
`;

const ThankYouForm = ({level, transactionId, correct = false, hasEmail = false}) => {
  const [email, setEmail] = useState("");
  const [terms, setTerms] = useState(false);
  const [newsletter, setNewsletter] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  // @ts-ignore
  const base_url = import.meta.env.VITE_BACKEND_BASE_URL;
  const save_url = `${base_url}/questions/savemail`;

  const day = (new Date().getTime() - Date.parse("2022-12-01 00:00")) / 1000 / 60 / 60 / 24;

  let state = "new";

  if (correct) {
    if (submitted) {
      state = "submitted";
    } else {
      if (level < day) {
        state = "old";
      } else {
        if (hasEmail) {
          state = "repeat";
        } else {
          state = "new";
        }
      }
    }
  } else {
    state = "wrong";
  }

  return (
    <ThankYouFormStyled>
      {state === "submitted" ? (
        <>
          <Headline>Thank you for your participation!</Headline>
          <Text>
            Return in the next days to improve your chances.
          </Text>
        </>
      ) : null}
      {state === "old" ? (
        <>
          <Headline>Thank you for your participation!</Headline>
          <Text>
            You can only take part in our raffle on the current day.
          </Text>
        </>
      ) : null}
      {state === "repeat" ? (
        <>
          <Headline>Thank you for your participation!</Headline>
          <Text>
            With your repeated participation you increase your chances of winning.
          </Text>
        </>
      ) : null}
      {state === "wrong" ? (
        <>
          <Headline>Wrong answer</Headline>
          <Text>
            Unfortunately that was the wrong answer. Please try again tomorrow!
          </Text>
        </>
      ) : null}
      {state === "new" ? (
        <>
          <Headline>Thank you for your participation!</Headline>
          <Text>
            As a small thank you, you can now take part in our raffle. Please enter your email address:
          </Text>
          <SubmissionForm onSubmit={e => {
            e.preventDefault();
            const formData = new FormData();
            formData.append("TransactionId", transactionId);
            formData.append("Email", email);
            formData.append("Number", level);
            formData.append("Newsletter", newsletter ? "1" : "0");
            formData.append("Terms", terms ? "1" : "0");

            fetch(save_url, {
              method: "POST",
              body: formData
            }).then(r => r.text()).then(id => {
              if (id) {
                setTransactionId(id);
              }
              setSubmitted(true);
            });
          }}>
            <ButtonItem>
              <Input type="email" placeholder="Email" required value={email}
                     onChange={e => setEmail(e.target.value)}/>
            </ButtonItem>
            <ButtonItem>
              <CheckboxLabel>
                <Checkbox type="checkbox" required checked={terms} onChange={e => setTerms(e.target.checked)}/>
                I have read the <Link href="https://www.nkt.com/terms-of-use-xMas" target="_blank">terms and
                conditions </Link>
                and the <Link href="https://www.nkt.com/data-privacy-xMas" target="_blank">privacy policy
                information</Link>.
              </CheckboxLabel>
            </ButtonItem>
            <ButtonItem>
              <CheckboxLabel>
                <Checkbox type="checkbox" checked={newsletter} onChange={e => setNewsletter(e.target.checked)}/>
                I would like to subscribe to the NKT newsletter.
              </CheckboxLabel>
            </ButtonItem>
            <ButtonItem>
              <Button variant="highlight">Submit</Button>
            </ButtonItem>
          </SubmissionForm>
        </>
      ) : null}
      <Text>
        If you want to continue playing, you can help the Christmas elves to connect the christmas tree.
      </Text>
      <ButtonItem>
        <Button onClick={() => location.href = `/game/${level}`}>To the game</Button>
      </ButtonItem>
      <ButtonItem>
        <Button onClick={() => location.href = '/quiz'}>Back to overview</Button>
      </ButtonItem>
    </ThankYouFormStyled>
  );
}

export default ThankYouForm;
