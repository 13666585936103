import Animated from "animated";
import {useEffect, useState} from "react";
import {Container, Sprite} from "react-pixi-fiber";
import SpineSpriteComponent from "./SpineSpriteComponent";
import {Loader} from "pixi.js";
import * as PIXI from "pixi.js";

const AnimatedContainer = Animated.createAnimatedComponent(Container);

const SpineSprite = ({name, src, x, y, width, height, animation}) => {
  const [data, setData] = useState(null);

  console.log(name, data);

  useEffect(() => {

    Loader.shared
      .add(name, src)
      .load(res => {
        setData(res.resources[name].spineData)
      });

  }, []);
  return <AnimatedContainer x={x} y={y} width={width} height={height}>
    {data ? <SpineSpriteComponent data={data} x={width/2} y={height/2} width={width} height={height} animation={animation}/> : null}
  </AnimatedContainer>
};

export default SpineSprite;