// @ts-nocheck
import styled from "styled-components";
import desktop_bg from './LevelOverviewDesktop-Background_v3.png';
import desktop_first from './LevelOverviewDesktop-First.png';
import desktop_fg from './LevelOverviewDesktop-Foreground.svg';

import mobile_bg from './LevelOverviewMobile-Background_v2.png';
import mobile_first from './LevelOverviewMobile-First.svg';
import mobile_fg from './LevelOverviewMobile-Foreground.svg';

import media from '../../utilities/media.js';
import WindTurbine from "../WindTurbine/WindTurbine";

const DesktopLayer = styled.img`
  display: none;
  pointer-events: none;
  position: absolute;
  top:0;
  left:0;
  width:100%;
  ${media.moreThan("lg")} {
    display: block;
  }
`;

const MobileLayer = styled.img`
  display: block;
  pointer-events: none;
  position: absolute;
  top:0;
  left:0;
  width:100%;
  ${media.moreThan("lg")} {
    display: none;
  }
`;

const QuizBackgroundStyled = styled.div`
  position: relative;
  padding-bottom: calc(100% / 3 * 10);
  
  ${media.moreThan("lg")} {
    padding-bottom: calc(100% / 4 * 3);
  }
  ${media.moreThan("xlm")} {
    width: 1400px;
    height: 950px;
    padding-bottom:0;
    overflow: hidden;
  }
`;

const WindTurbine1 = styled.div`
  position: absolute;
  top: 1%;
  left: 55%;
  width: 20%;
  
  ${media.moreThan("lg")} {
    top: 0;
    left: 30%;    
    width: 8%;
  }
`;

const QuizBackground = ({ children }) => (
  <QuizBackgroundStyled>
    <MobileLayer src={mobile_bg} />
    <DesktopLayer src={desktop_bg} />
    <WindTurbine1>
      <WindTurbine rotation={1}/>
    </WindTurbine1>
    <MobileLayer src={mobile_first} />
    <DesktopLayer src={desktop_first} />
    { children }
    <DesktopLayer src={desktop_fg} />
    <MobileLayer src={mobile_fg} />
  </QuizBackgroundStyled>
);

export default QuizBackground;
