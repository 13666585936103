import styled from "styled-components";
import Button, {ButtonStatus, ButtonStyled} from "../../ui/Button/Button";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const Headline = styled.h1`
  color: var(--color-primary);
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 20px;
`;

const ThankYouFormStyled = styled.div`
`;

const SubmissionForm = styled.div`
  padding: 20px 0;
  margin: 0 auto 20px;
`;

const ButtonItem = styled.div`
  padding: 10px 0;
  max-width: 300px;
  margin:0 auto;
`;

const Input = styled.input`
  border: 2px solid var(--color-grey);
  font-size: 16px;
  width: 100%;
  padding: 10px 20px;
`;

const ThankYouGameForm = () => {
  return (
    <ThankYouFormStyled>
      <Headline>Thank you for helping!</Headline>
      <Text>
        Now the house is prepared for christmas. Please return on all days to help the other Christmas elves!
      </Text>
      <ButtonItem>
        <Button onClick={() => location.href='/quiz'}>Back to overview</Button>
      </ButtonItem>
    </ThankYouFormStyled>
  );
}

export default ThankYouGameForm;
