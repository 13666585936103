import styled from "styled-components";
import {useParams} from "react-router-dom";
import media from "../../utilities/media";

// @ts-ignore
import quiz_bg_mobile from "./LabyrinthMobile-Background_v2.png";
// @ts-ignore
import quiz_bg_desktop from "./LabyrinthDesktop-Background_v3.png";

// @ts-ignore
import horizont from "./nkt_game_horizont_web.png";
import Maze from "../Maze/Maze";
import House, {HouseStates} from "../House/House";
import WindTurbine from "../WindTurbine/WindTurbine";
import QuizForm from "../QuizForm/QuizForm";
import {useEffect, useState} from "react";
import Button from "../../ui/Button/Button";
import Modal from "../Modal/Modal";
import ThankYouForm from "../ThankYouForm/ThankYouForm";
import AlreadyPlayedForm from "../AlreadyPlayedForm/AlreadyPlayedForm";

const QuizDayStyled = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: calc(100% / 1125 * 2436 + 160px);
  background-color: #6EB381;

  ${media.moreThan("lg")} {
    padding-bottom: calc(100% / 4 * 3);
  }
`;

const Horizont = styled.div`
  position: absolute;
  top:0;
  left:0;
  z-index:2;
  width: 100%;
  height: 150px;
  background: url(${horizont}) no-repeat;
`;

const QuizDayBackground = styled.div`
  position: absolute;
  top:0;
  left:0;
  z-index:2;
  width: 100%;
  height: 100%;
  background: url(${quiz_bg_mobile}) no-repeat top center;
  background-size: 100%;

  ${media.moreThan("lg")} {
    background: url(${quiz_bg_desktop}) no-repeat left top;
    background-size: 100%;
  }
`;

const MazeContainer = styled.div`
  position: absolute;
  top: 12%;
  left: 0;
  width: 95%;

  ${media.moreThan("lg")} {
    left:0;
    top:0;
    width: 57.7%;
  }
`;

const HouseContainer = styled.div`
  position: absolute;
  top:30%;
  left:5%;
  width: 30%;

  ${media.moreThan("lg")} {
    top:50%;
    left:7%;
    width: 12%;
  }
`;

const WindTurbineContainer = styled.div`
  position: absolute;
  top:0%;
  left:57%;
  width: 30%;

  ${media.moreThan("lg")} {
    left: 39%;
    width: 9%;
  }
`;

const QuizFormContainer = styled.div`
  position: absolute;
  top:44%;
  left:15%;
  width: 70%;

  ${media.moreThan("lg")} {
    top: 23%;
    left: 65%;
    width: 32%;
  }
`;

const ContinueContainer = styled.div`
  max-width: 200px;
  margin: 0 auto 20px;
`;

const HelpTextContainer = styled.div`
  max-width: 300px;
  margin: 0 auto;
`;

const HelpText = styled.a`
  display: block;
  position: relative;
  padding-left: 40px;
  padding-top: 7px;
  color: var(--color-primary);
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const Svg = styled.svg`
  width: 30px;
  height: 30px;
  position: absolute;
  top:0;
  left:0;
  
  path {
    fill: var(--color-primary);
  }
`;

const InfoIcon = () => (
  <Svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
    <path d="m600 1128c291.61 0 528-236.39 528-528s-236.39-528-528-528-528 236.39-528 528 236.39 528 528 528zm0-96c238.59 0 432-193.41 432-432s-193.41-432-432-432-432 193.41-432 432 193.41 432 432 432zm-60-443.95c0-33.168 26.93-60.055 60-60.055 33.137 0 60 26.766 60 60.055v239.89c0 33.168-26.93 60.055-60 60.055-33.137 0-60-26.766-60-60.055zm-12-204.05c0-39.766 32.316-72 72-72 39.766 0 72 32.316 72 72 0 39.766-32.316 72-72 72-39.766 0-72-32.316-72-72z" fill-rule="evenodd"/>
  </Svg>
);


const QuizDay = ({ data, score, transactionId }) => {
  if(!data) return null;
  const { day } = useParams();
  const dataMap = data.reduce((acc, item) => {
    acc[item.Number] = item;
    return acc;
  }, {});
  const dayData = dataMap[day];
  const oldAnswer = score && score.quiz ? score.quiz[day] : 0;
  const participated = score && score.participated ? score.participated[day] : 0;
  const hasEmail = score && score.email;
  const [selected , setSelected] = useState(oldAnswer);
  const [showThankYou, setShowThankYou] = useState(false);

  // Update
  useEffect(() => {
    if (oldAnswer) {
      setSelected(oldAnswer);
    }
  }, [score]);

  // @ts-ignore
  const base_url = import.meta.env.VITE_BACKEND_BASE_URL;
  const save_url = `${base_url}/questions/save`;

  return <QuizDayStyled>
    <Modal show={showThankYou}>
      {participated ? <AlreadyPlayedForm level={day}/> : (
        <ThankYouForm
          level={day}
          correct={selected === dayData.CorrectAnswer}
          transactionId={transactionId}
          hasEmail={hasEmail}
        />
      )}
    </Modal>
    <Horizont />
    <QuizDayBackground>
      <MazeContainer>
        <Maze day={day} answer={dayData.CorrectAnswer} selected={selected}/>
      </MazeContainer>
      <HouseContainer>
        <House
          number={dayData.Number}
          variant={`house${dayData.Variant}`}
          state={selected === dayData.CorrectAnswer ? HouseStates.solved : HouseStates.active}
          width="100%"
          mobileWidth="100%"
        />
      </HouseContainer>
      <WindTurbineContainer>
        <WindTurbine rotation={selected === dayData.CorrectAnswer}/>
      </WindTurbineContainer>
      <QuizFormContainer>
        <QuizForm
          text="Choose the right cable to light up the house"
          question={dayData.Question}
          answers={[
            {id: 1, text: dayData.Answer1},
            {id: 2, text: dayData.Answer2},
            {id: 3, text: dayData.Answer3},
            // {id: 4, text: dayData.Answer4},
          ]}
          correctAnswerId={dayData.CorrectAnswer}
          selected={selected}
          onAnswer={(id) => {
            setSelected(id);
            const formData = new FormData();
            formData.append("Number", dayData.Number);
            formData.append("TransactionId", transactionId);
            formData.append("Answer", id);
            fetch(save_url, {
              method: "POST",
              body: formData
            }).then(r => r.json()).then(r => {
              console.log(r);
            });
          }}
        />
        <ContinueContainer>
          { selected ? (
            <Button onClick={() => setShowThankYou(true)}>Continue</Button>
          ) : (
            null
          ) }
        </ContinueContainer>
        <HelpTextContainer>
          <HelpText href={dayData.LinkedIn} target="_blank">
            <InfoIcon />
            Need help? More info on LinkedIn
          </HelpText>
        </HelpTextContainer>
      </QuizFormContainer>
    </QuizDayBackground>
  </QuizDayStyled>;
}

export default QuizDay;
