/// <reference types="vite-plugin-svgr/client" />

import styled from "styled-components";
import media from "../../utilities/media.js";
import {useMediaQuery} from "../../utilities/useMediaQuery";
import houseMap from "./houseMap";

import { ReactComponent as Tree} from "./tree.svg";
// @ts-ignore
import smoke from './assets/chimneysmoke.gif';

const HouseStyled = styled.div`
  z-index: 1;
  cursor: pointer;
  
  ${props => props.disabled ? `
    filter: brightness(0.5);
  ` : null}
  ${props => props.active ? `
    &:hover {
      filter: brightness(1.2);
    }
  ` : null}
  ${media.moreThan("md")} {

  }
`;

const HouseImage = styled.img`
  position: relative;
  display: block;
  width: 100%;
  z-index: 2;
`;

const HouseNumber = styled.div`
  position: absolute;
  top: 24%;
  left: 50%;
  transform: translateX(-60%);
  background-color: #4679b1;
  border-radius: 50%;
  color: white;
  z-index: 3;
  width: 20px;
  height: 20px;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  
  ${media.moreThan("lg")} {
    font-size: 16px;
  }
  
`;

const TreeStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 15%;
  width: 20%;
  z-index: 2;

  svg {
    width: 100%;
    height: auto;
  }
`;

export enum HouseStates {
  disabled = 1,
  active = 2,
  solved = 3,
}

const HouseSmoke = styled.img`
  position: absolute;
  top: -10%;
  right: 15%;
  width: 35%;
  height: 30%;
  opacity: 0.8;
  z-index: 1;
`;

const House = (
  {
    posX = "0",
    posY = "0",
    width = "10%",
    mobilePosX = "0",
    mobilePosY = "0",
    mobileWidth = "30%",
    variant = "house1",
    number = 1,
    state = HouseStates.disabled,
    gameSolved = false,
    onClick = () => {
    },
  }) => {

  const isDesktop = useMediaQuery('(min-width: 992px)');
  const houseVariant = houseMap[variant];
  const image = state == HouseStates.solved ? houseVariant.srcHigh : houseVariant.src;
  const disabled = state == HouseStates.disabled;

  const style = posX ? {
    position: 'absolute',
    left: isDesktop ? posX : mobilePosX,
    top: isDesktop ? posY : mobilePosY,
    width: isDesktop ? width : mobileWidth,
  } : {
    position: 'relative',
  }

  return (
    <HouseStyled
      disabled={disabled}
      active={state == HouseStates.active}
      onClick={() => !disabled ? onClick() : null}
      style={style}>
      {gameSolved ? <HouseSmoke src={smoke}/> : null}
      <HouseNumber>{number}</HouseNumber>
      <HouseImage src={image}/>
      {gameSolved ? <TreeStyled>
        <Tree/>
      </TreeStyled> : null}
    </HouseStyled>
  );
}

export default House;
