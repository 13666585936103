import styled from "styled-components";

export const ButtonStyled = styled.button`
  display: block;
  position: relative;
  width: 100%;
  border: none;
  background-color: var(--color-primary);
  color: var(--color-white);
  font-weight: bold;
  padding: ${props => props.quiz ? '14px 10px 14px 50px' : '14px 30px'};
  cursor: ${props => props.status != ButtonStatus.default ? "not-allowed" : "pointer"};
  font-size: 16px;
  text-align: ${props => props.quiz ? 'left' : 'center'};
  
  ${props => (props.status == ButtonStatus.correct) && `background-color: var(--color-highlight);`}
  ${props => (props.status == ButtonStatus.wrong) && `background-color: var(--color-error);`}

  ${props => (props.status == ButtonStatus.correctDisabled) && `opacity:0.5;`}
  ${props => (props.status == ButtonStatus.wrongDisabled) && `opacity:0.5;`}
  
  ${props => (props.variant == 'highlight') && `background-color: var(--color-highlight);`}
`;

const ButtonNumber = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  top: 24px;
  width: 30px;
  height: 30px;
  border: 1px solid var(--color-white);
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
`;

const ButtonStatusDisplay = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  width: 30px;
  height: 30px;
  border: 1px solid var(--color-white);
  border-radius: 50%;
  line-height: 30px;
  padding: 4px;
`;

export enum ButtonStatus {
  default = 0,
  correct = 1,
  wrong = 2,
  correctDisabled = 3,
  wrongDisabled = 4,
}

const Ok = styled.span`
  display: block;
  width: 10px;
  height: 20px;
  border-right: 2px solid var(--color-white);
  border-bottom: 2px solid var(--color-white);
  transform: rotate(45deg);
  margin-left:5px;
  margin-top: -2px;
`;

const NotOk = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  position:relative;
  
  &:before {
    content: "";
    display: block;
    width: 20px;
    height: 2px;
    background-color: var(--color-white);
    position: absolute;
    top: 50%;
    margin-top: -1px;
    transform: rotate(45deg);
  }
  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 2px;
    background-color: var(--color-white);
    position: absolute;
    top: 50%;
    margin-top: -1px;
    transform: rotate(-45deg);
  }
`;

const Button = ({
  children,
  onClick = () => {},
  number = 0,
  status = ButtonStatus.default,
  delay = 0,
  variant = "default",
}) => <ButtonStyled onClick={onClick} status={status} variant={variant} quiz={number || status !== ButtonStatus.default}>
  {number ? <ButtonNumber>{number}</ButtonNumber> : null}
  {status !== ButtonStatus.default ? <ButtonStatusDisplay>
    {(status === ButtonStatus.correct || status === ButtonStatus.correctDisabled) ? <Ok/> : null}
    {(status === ButtonStatus.wrong || status === ButtonStatus.wrongDisabled) ? <NotOk/> : null}
  </ButtonStatusDisplay> : null}
  {children}
</ButtonStyled>

export default Button;
