import {createSlice} from '@reduxjs/toolkit';
import {Simulate} from "react-dom/test-utils";

export enum playerStates {
  PREP = 'PREP',
  IDLE = 'IDLE',
  MOVING_LEFT = 'MOVING_LEFT',
  MOVING_RIGHT = 'MOVING_RIGHT',
  MOVING_UP = 'MOVING_UP',
  MOVING_DOWN = 'MOVING_DOWN',
  BLOCKED_LEFT = 'BLOCKED_LEFT',
  BLOCKED_RIGHT = 'BLOCKED_RIGHT',
  BLOCKED_UP = 'BLOCKED_UP',
  BLOCKED_DOWN = 'BLOCKED_DOWN',
}

const blockedStatesMap = {
  'PREP': playerStates.IDLE,
  'IDLE': playerStates.IDLE,
  'MOVING_LEFT': playerStates.BLOCKED_LEFT,
  'MOVING_RIGHT': playerStates.BLOCKED_RIGHT,
  'MOVING_UP': playerStates.BLOCKED_UP,
  'MOVING_DOWN': playerStates.BLOCKED_DOWN,
  'BLOCKED_LEFT': playerStates.BLOCKED_LEFT,
  'BLOCKED_RIGHT': playerStates.BLOCKED_RIGHT,
  'BLOCKED_UP': playerStates.BLOCKED_UP,
  'BLOCKED_DOWN': playerStates.BLOCKED_DOWN,
}

const slideMap = {
  'MOVING_LEFT' : { payload: 'slide-left'},
  'MOVING_RIGHT' : { payload: 'slide-right'},
  'MOVING_UP' : { payload: 'slide-up'},
  'MOVING_DOWN' : { payload: 'slide-down'},
  'BLOCKED_LEFT' : { payload: 'slide-left'},
  'BLOCKED_RIGHT' : { payload: 'slide-right'},
  'BLOCKED_UP' : { payload: 'slide-up'},
  'BLOCKED_DOWN' : { payload: 'slide-down'},
}

const initialState = {
  state: playerStates.PREP,
  x: -1,
  y: 0,
  nextX: 0,
  nextY: 0,
  walls: [],
  queuedActions: [],
  transactionId: 0,
  level: 0,
  start: '0-0',
  tree: '',
  socket: '',
  startWire: false,
  wired: [],
  ice: [],
  boxes: [],
  finished: false,
  mapHeight: 10,
  mapWidth: 10,
  description: '',
};

const handleAction = (state, action) => {
  switch (action.payload) {
    case 'left':
      state.nextX = state.x - 1;
      state.state = playerStates.MOVING_LEFT;
      break;
    case 'right':
      state.nextX = state.x + 1;
      state.state = playerStates.MOVING_RIGHT;
      break;

    case 'up':
      state.nextY = state.y - 1;
      state.state = playerStates.MOVING_UP;
      break;
    case 'down':
      state.nextY = state.y + 1;
      state.state = playerStates.MOVING_DOWN;
      break;
    case 'slide-left':
      state.nextX = state.x - 1;
      state.state = playerStates.BLOCKED_LEFT;
      break;
    case 'slide-right':
      state.nextX = state.x + 1;
      state.state = playerStates.BLOCKED_RIGHT;
      break;
    case 'slide-up':
      state.nextY = state.y - 1;
      state.state = playerStates.BLOCKED_UP;
      break;
    case 'slide-down':
      state.nextY = state.y + 1;
      state.state = playerStates.BLOCKED_DOWN;
      break;
  }

  // Blocked by limit
  if (state.nextX < 0 || state.nextX > state.mapWidth - 1 || state.nextY < 0 || state.nextY > state.mapHeight - 1) {
    state.nextX = state.x;
    state.nextY = state.y;
    state.state = blockedStatesMap[state.state];
  }

  // Blocked by wall
  if (state.walls.includes(`${state.nextX}-${state.nextY}`)) {
    state.nextX = state.x;
    state.nextY = state.y;
    state.state = blockedStatesMap[state.state];
    if (state.queuedActions.length > 0) {
      state.queuedActions = []; // Empty queue
    }
  }

  const boxes = JSON.parse(JSON.stringify(state.boxes));
  // console.log(action.payload, state.nextX, state.nextY, boxes);
  boxes.forEach((box) => {

    if (box.x === state.nextX && box.y === state.nextY) {
      switch(action.payload) {
        case 'left':
        case 'slide-left':
          box.nextX = box.x - 1;
          break;
        case 'right':
        case 'slide-right':
          box.nextX = box.x + 1;
          break;
        case 'up':
        case 'slide-up':
          box.nextY = box.y - 1;
          break;
        case 'down':
        case 'slide-down':
          box.nextY = box.y + 1;
          break;
      }
      // Blocked by limit
      if (box.nextX < 0 || box.nextX > state.mapWidth - 1 || box.nextY < 0 || box.nextY > state.mapHeight - 1) {
        state.nextX = state.x;
        state.nextY = state.y;
        state.state = blockedStatesMap[state.state];
        box.nextX = box.x;
        box.nextY = box.y;
      }
      // Blocked by wall
      if (state.walls.includes(`${box.nextX}-${box.nextY}`)) {
        state.nextX = state.x;
        state.nextY = state.y;
        state.state = blockedStatesMap[state.state];
        box.nextX = box.x;
        box.nextY = box.y;
      }
      // Blocked by box
      boxes.forEach((box2) => {
        if (box2.x === box.nextX && box2.y === box.nextY) {
          state.nextX = state.x;
          state.nextY = state.y;
          state.state = blockedStatesMap[state.state];
          box.nextX = box.x;
          box.nextY = box.y;
        }
      });
    }
  });
  state.boxes = boxes;

  state.description = '';
}

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    move: (state, action) => {
      console.log('move', action.payload);
      if (state.nextX !== state.x || state.nextY !== state.y) {
        console.log('Queued', action.payload, state.nextX, state.nextY, state.x, state.y);
        state.queuedActions.push(action);
        return;
      }
      handleAction(state, action);
    },
    endMove: (state) => {
      // console.log('endMove', state.nextX, state.nextY);
      state.x = state.nextX;
      state.y = state.nextY;
      // state.state = blockedStatesMap[state.state];
      // console.log('endMove2', state.x, state.y, state.nextX, state.nextY);


      // Ice
      if (state.ice.includes(`${state.x}-${state.y}`)) {
        handleAction(state, slideMap[state.state]);
        if (state.queuedActions.length > 0) {
          state.queuedActions = []; // Empty queue
        }
      } else {
        if (state.queuedActions.length > 0) {
          handleAction(state, state.queuedActions.shift());
        } else {
          state.state = blockedStatesMap[state.state];
        }
      }

      if (state.startWire) {
        state.wired.push(`${state.x}-${state.y}`);
      }
    },
    loadLevel: (state, action) => {
      // console.log ('loadLevel', action.payload);
      const {
        level,
        transactionId,
        mapHeight,
        mapWidth,
        walls,
        specials,
        ice,
        boxes,
        description,
      } = action.payload;
      state.level = level;
      state.transactionId = transactionId;
      state.mapHeight = mapHeight;
      state.mapWidth = mapWidth;
      state.walls = walls
      state.ice = ice;
      state.boxes = boxes.map((boxString, index) => {
        const [x, y] = boxString.split('-');
        return ({
          id: index,
          x: parseInt(x),
          y: parseInt(y),
          nextX: parseInt(x),
          nextY: parseInt(y),
        });
      });


      const {start, tree, socket} = specials;
      const [x, y] = start.split('-').map(val => parseInt(val));
      state.x = x;
      state.nextX = x;
      state.y = y;
      state.nextY = y;
      state.tree = tree;
      state.state = playerStates.IDLE;
      state.socket = socket;
      state.description = description;
    },
    wire: (state, action) => {
      if(state.wired.length === 0) {
        state.startWire = true;
      }
      state.wired.push(action.payload);
    },
    finish: (state, action) => {
      state.finished = true;

      // @ts-ignore
      const base_url = import.meta.env.VITE_BACKEND_BASE_URL;
      const save_url = `${base_url}/questions/savegame`;
      const formData = new FormData();
      formData.append("Number", String(state.level));
      formData.append("TransactionId", String(state.transactionId));
      fetch(save_url, {
        method: "POST",
        body: formData
      }).then(r => r.json()).then(r => {
        console.log(r);
      });
    },
    endBox: (state, action) => {
      const boxes = JSON.parse(JSON.stringify(state.boxes));
      boxes.forEach((box) => {
        if (box.id === action.payload) {
          box.x = box.nextX;
          box.y = box.nextY;
        }
      });
      state.boxes = boxes;
    },
    setDescription (state, action) {
      state.description = action.payload;
    }
  },
});

export const {move, endMove, loadLevel, wire, finish, endBox, setDescription} = playerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPosition = (state) => {
  return state.player;
};

export default playerSlice.reducer;
