import styled from "styled-components";

const HintStyled = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  padding: 20px;
  background: #fff;
  font-size: 16px;
  line-height: 1.4;
`;

const Hint = ({ children }) => {
  return <HintStyled>
    {children}
  </HintStyled>
}

export default Hint;
