import {GlobalHotKeys} from "react-hotkeys";
import {useDispatch, useSelector} from "react-redux";
import {move} from "../GameState/player/playerSlice";

const KeyboardController = () => {
  const keyMap = {
    MOVE_UP: ["up", "w"],
    MOVE_DOWN: ["down", "s"],
    MOVE_LEFT: ["left", "a"],
    MOVE_RIGHT: ["right", "d"],
  }

  const dispatch = useDispatch();

  const handlers = {
    MOVE_UP: () => dispatch(move('up')),
    MOVE_DOWN: () => dispatch(move('down')),
    MOVE_LEFT: () => dispatch(move('left')),
    MOVE_RIGHT: () => dispatch(move('right')),
  };

  return <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
}

export default KeyboardController;
