import React from 'react'
import {createRoot} from "react-dom/client";
import Game from "./game/Game.tsx";
import {Provider} from "react-redux";
import store from "./store";
import GlobalStyle from "./styles/globalstyle";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Editor from "./editor/Editor";
import Quiz from "./quiz/Quiz";
import Layout from "./ui/Layout/Layout";
import {create_UUID, getTransactionId} from "./utilities/transactionid";

const container = document.getElementById("root");
const root = createRoot(container);

function App() {
  const id = getTransactionId();

  return (
    <div className="App">
      <GlobalStyle/>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Navigate to="/quiz/"/>}/>
            <Route path="/quiz/*" element={<Quiz transactionId={id}/>}/>
            <Route path="/game/:level" element={
              <Game transactionId={id}/>
            }/>
            <Route path="/editor/:level" element={<Editor/>}/>
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  )
}

root.render(
  <Provider store={store}>
    <App/>
  </Provider>
)
