import {Swipeable, defineSwipe} from 'react-touch';
import {useDispatch, useSelector} from "react-redux";
import {move} from "../GameState/player/playerSlice";

const TouchController = ({ children }) => {
  const dispatch = useDispatch();

  const handleTap = () => {
    alert("you have taped me");
  }

  const handleSwipe = (direction) => {
    console.log("you have swiped me ", direction);
    dispatch(move(direction));
  };

  return <Swipeable
    config={defineSwipe({
      swipeDistance: 50,
    })}
    onSwipeLeft={() => handleSwipe("left")}
    onSwipeRight={() => handleSwipe("right")}
    onSwipeUp={() => handleSwipe("up")}
    onSwipeDown={() => handleSwipe("down")}
  >
    {children}
  </Swipeable>
}

export default TouchController;