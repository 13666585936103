import Animated from 'animated';
import Easing from "animated/lib/Easing";
import {Sprite} from "react-pixi-fiber";
import {useEffect, useState} from "react";
import {endBox, endMove} from "../GameState/player/playerSlice";
import {useDispatch} from "react-redux";

const AnimatedSprite = Animated.createAnimatedComponent(Sprite);

const Box = ({id, x, y, nextX, nextY, tileset}) => {
  const [posX] = useState(new Animated.Value(x * 256));
  const [posY] = useState(new Animated.Value(y * 256));

  const dispatch = useDispatch();

  useEffect(() => {
    if (nextX != x) {
      Animated.timing(posX, {
        toValue: nextX * 256,
        duration: 500,
        easing: Easing.linear(Easing.ease),
        // easing: Easing.bounce,
      }).start(() => {
        dispatch(endBox(id));
      });
    }
    if (nextY != y) {
      Animated.timing(posY, {
        toValue: nextY * 256,
        duration: 500,
        // easing: Easing.bounce,
      }).start(() => {
        dispatch(endBox(id));
      });
    }
  }, [x, y, nextX, nextY])

  return <AnimatedSprite
    x = {posX}
    y = {posY}
    width = {258}
    height = {258}
    texture={tileset.getTexture(0, 1)}
  />
}

export default Box;
