import robotoRegularWoff2 from './fonts/roboto-v20-latin-ext_latin-regular.woff2';
import robotoRegularWoff from './fonts/roboto-v20-latin-ext_latin-regular.woff';
import robotoBoldWoff2 from './fonts/roboto-v20-latin-ext_latin-700.woff2';
import robotoBoldWoff from './fonts/roboto-v20-latin-ext_latin-700.woff';

const fonts = `
  @font-face {
    font-family: Roboto;
    font-weight: normal;
    src: url('${robotoRegularWoff2}') format('woff2'),
    url('${robotoRegularWoff}') format('woff');
    font-display: swap;
  }
  @font-face {
    font-family: Roboto;
    font-weight: bold;
    src: url('${robotoBoldWoff2}') format('woff2'),
    url('${robotoBoldWoff}') format('woff');
    font-display: swap;
  }
`;

export default fonts;
