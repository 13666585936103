import reset from "styled-reset";
import { createGlobalStyle } from "styled-components";
import fonts from "./fonts";
import usercentrics from "./usercentrics";

// global css
const GlobalStyle = createGlobalStyle`

  ${reset};
  ${fonts};
  
  html {
    scroll-behavior: smooth;
    text-size-adjust: 100%;
    font-family: 'Roboto', sans-serif;
    
    --color-primary: #1E326E;
    --color-secondary: #009FDF;
    --color-highlight: #FF6A00;
    --color-error: #bd1622;
    --color-grey: #cccccc;
    --color-white: #FFFFFF;
  }
  
  a:link, a:visited {
    color: var(--color-primary);
  }

  .sb-show-main.sb-main-padded {
    padding: 0;
  }
  
  #root {
    width: 100%;
  }
  
  #__next {
    position:relative;
  }

  *,
  :before,
  :after {
    box-sizing: border-box !important;
  }

  input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
  input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }

  .ReactModal__Overlay {
    opacity: 0;
    z-index:1001 !important;
    transition: opacity 0.5s ease-in-out;
  }
  
  .ReactModal__Overlay--after-open{
      opacity: 1;
  }
  
  .ReactModal__Overlay--before-close{
      opacity: 0;
  }
  
  ${usercentrics};

`;

export default GlobalStyle;
