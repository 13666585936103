import house1 from './assets/IMG_0094.png';
import house1_high from './assets/IMG_0095.png';
import house2 from './assets/IMG_0097.png';
import house2_high from './assets/IMG_0098.png';
import house3 from './assets/IMG_0099.png';
import house3_high from './assets/IMG_0100.png';
import house4 from './assets/IMG_0101.png';
import house4_high from './assets/IMG_0102.png';
import house5 from './assets/IMG_0103.png';
import house5_high from './assets/IMG_0104.png';
import house6 from './assets/IMG_0105.png';
import house6_high from './assets/IMG_0106.png';
import house7 from './assets/IMG_0107.png';
import house7_high from './assets/IMG_0108.png';
import house8 from './assets/IMG_0109.png';
import house8_high from './assets/IMG_0110.png';
import house9 from './assets/IMG_0111.png';
import house9_high from './assets/IMG_0112.png';
import house10 from './assets/IMG_0113.png';
import house10_high from './assets/IMG_0114.png';


const houseMap = {
  house1: {
    src: house1,
    srcHigh: house1_high,
  },
  house2: {
    src: house2,
    srcHigh: house2_high,
  },
  house3: {
    src: house3,
    srcHigh: house3_high,
  },
  house4: {
    src: house4,
    srcHigh: house4_high,
  },
  house5: {
    src: house5,
    srcHigh: house5_high,
  },
  house6: {
    src: house6,
    srcHigh: house6_high,
  },
  house7: {
    src: house7,
    srcHigh: house7_high,
  },
  house8: {
    src: house8,
    srcHigh: house8_high,
  },
  house9: {
    src: house9,
    srcHigh: house9_high,
  },
  house10: {
    src: house10,
    srcHigh: house10_high,
  },
}

export default houseMap;
