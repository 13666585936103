import { css } from 'styled-components';

const usercentrics = css`
  .uc-powered-by-footer {
    display: none !important;
  }
  
  #usercentrics-button .uc-privacy-button-wrapper {
    left: auto !important;
    right: 20px !important;
    bottom: 7px !important;
  }

  .uc-btn-new,
  .uc-btn-accept,
  .uc-border-radius-small,
  #usercentrics-button #uc-center-modal .secondary-basic .uc-card-footer .uc-save-settings-button,
  #usercentrics-button #uc-center-modal .secondary-advance .uc-card-footer .uc-save-settings-button {
    border-radius: 0px !important;
  }

  .uc-btn-new,
  .uc-btn-accept {
    height: 50px;

    font-family: Roboto, Arial, sans-serif;
    font-size: 1rem;
    letter-spacing: 0.2px;
    font-weight: bold;
    line-height: 1.3125rem;
    vertical-align: middle;
    text-align: center;
    transition: border-color 0.3s ease-in-out 0s, background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(255, 106, 0);
    border-image: initial;
  }

  /* Unused */
  #uc-btn-deny-banner.uc-btn-deny-old {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(25, 25, 25) !important;

    transition: border-color 0.3s ease-in-out 0s, background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(255, 106, 0);
    border-image: initial;
  }

  #uc-btn-deny-banner.uc-btn-deny {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(255, 106, 0) !important;
    border: 3px solid rgb(255, 106, 0) !important;
    height: 50px;
    padding: 0;
  }

  #uc-banner-centered .uc-banner-content .uc-banner-text h2 span {
    display: block;
    padding-top: 10px;
    font-family: Roboto, Arial, sans-serif !important;
    font-weight: normal;
    font-size: 18px !important;
    line-height: 24px !important;
    color: rgb(25, 25, 25) !important;
  }

  .uc-content.secondary-basic *,
  .uc-content.secondary-advance * {
    font-family: Roboto, Arial, sans-serif !important;
    font-size: 1.125rem !important;
    line-height: 1.25rem;
    color: rgb(25, 25, 25);
  }

  .uc-language-wrapper {
    padding: 4px !important;
  }

  #usercentrics-button #uc-center-modal .secondary-basic .uc-card-header,
  #usercentrics-button #uc-center-modal .secondary-advance .uc-card-header {
    background-color: rgb(30, 50, 110) !important;
    padding-top: 32px !important;
  }

  #usercentrics-button #uc-center-modal .secondary-advance .uc-card-body .uc-nav a.active, #usercentrics-button #uc-center-modal .secondary-advance .uc-card-body .uc-nav a:hover {
    color: rgb(30, 50, 110) !important;
  }

  #usercentrics-button #uc-center-modal .subtitle-text {
    font-size: 1rem !important;
  }

  #usercentrics-button #uc-center-modal .secondary-advance .uc-card-body .uc-category-row .uc-category-desc small,
  #usercentrics-button #uc-center-modal .secondary-basic .uc-card-body .uc-category-row .uc-category-desc small {
    color: rgb(25, 25, 25) !important;
    font-size: 1rem !important;
  }

  .user-centric-center-modal .uc-content {
    border-radius: 0px !important;
  }

  #usercentrics-button #uc-center-modal .secondary-basic .uc-card-footer .uc-save-settings-button,
  #usercentrics-button #uc-center-modal .secondary-advance .uc-card-footer .uc-save-settings-button {
    display: inline-block;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top: 18px !important;
    height: 50px;
    white-space: nowrap;
    font-family: Roboto, Arial, sans-serif;
    font-size: 1rem;
    letter-spacing: 0.2px;
    font-weight: bold;
    line-height: 1.3125rem;
    vertical-align: middle;
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: rgb(255, 106, 0) !important;
    transition: border-color 0.3s ease-in-out 0s, background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(255, 106, 0);
    border-image: initial;
  }

  #usercentrics-button #uc-privacy-chip {
    position: relative;
  }

  #usercentrics-button .privacy-chip-wrapper {
    border-radius: 0px !important;
    background-color: rgb(30, 50, 110) !important;

    font-family: Roboto, Arial, sans-serif !important;
    font-size: 1.125rem !important;
    line-height: 1.25rem;

    width: auto;
    max-width: 280px;
    padding: 8px 8px 5px;
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    bottom: 0;
    margin-right: 0;
  }

  #usercentrics-button .consent-setting-btn {
    font-family: Roboto, Arial, sans-serif !important;
    font-size: 0.875rem;
  }

  #usercentrics-button #uc-banner-centered .uc-banner-links a, #usercentrics-button #uc-banner-centered .uc-banner-links span {
    font-size: 1.125rem;
  }

  .usercentrics-button .uc-corner-modal .uc-card-footer .uc-save-settings-button {
    background-color: rgb(255, 106, 0) !important;
  }

  #usercentrics-button .uc-heading2, #usercentrics-button .uc-heading2 *, #usercentrics-button h2, #usercentrics-button h2 *, .uc-embed .uc-heading2, .uc-embed .uc-heading2 *, .uc-embed h2, .uc-embed h2 * {
    font-family: Georgia, Times, "Times New Roman", serif !important;
    font-size: 1.375rem !important;
    line-height: 1.75rem !important;
    margin-bottom: 16px !important;
    color: rgb(30, 50, 110) !important;
    text-align: left !important;
  }

  .usercentrics-button.layout-1 .uc-corner-modal .uc-corner-modal-content .uc-card-header {
    border-radius: 0 !important;
  }

  .usercentrics-button.layout-1 .uc-corner-modal .uc-corner-modal-content .uc-card-footer .uc-btn {
    border-radius: 0 !important;
  }

  #uc-banner-centered .uc-banner-btn-list {
    display: flex;
    flex-wrap: wrap;
  }

  #uc-banner-centered .uc-banner-content .uc-banner-btn-list .uc-btn-accept-wrapper, #uc-banner-centered .uc-banner-content .uc-banner-btn-list .uc-btn-deny-wrapper {
    width: 50% !important;
    padding-right: 10px;
    margin: 0;
  }

  #uc-banner-centered .uc-banner-content .uc-banner-btn-list .uc-btn-accept-wrapper.uc-btn-more-info-wrapper {
    padding-left: 10px;
    width: 100% !important;
  }

  @media (max-width: 400px) {
    #uc-banner-centered {
      max-height: calc(100% - 20px);
    }

    #uc-banner-centered .uc-banner-content {
      padding: 20px 20px 20px 20px;
    }

    .uc-banner-content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    #uc-banner-centered .uc-banner-content .uc-banner-text {
      overflow: auto;
    }

    #uc-banner-centered .uc-banner-content .uc-banner-text h2 {
      font-size: 16px !important;
      line-height: 22px !important;
      margin-bottom: 10px !important;
    }

    #uc-banner-centered .uc-banner-content .uc-banner-text h2 span {
      display: block;
      font-weight: normal;
      font-size: 12px !important;
      line-height: 17px !important;
      padding-right: 30px;
    }

    #usercentrics-button .uc-btn-new.uc-btn-more {
      height: 50px;
    }
  }
`;

export default usercentrics;
