import styled from "styled-components";
import Button from "../../ui/Button/Button";
import {Headline, Text, TextLink} from "../../ui/Typography/Typography";
// @ts-ignore
import wichtel from './assets/nkt_game_wichtel.png';

const ThankYouFormStyled = styled.div`
  z-index: 200;
`;

const ButtonItem = styled.div`
  padding: 10px 0;
  max-width: 300px;
  margin:0 auto;
`;

const IntroScreen = ({ onClose }) => {

  return (
    <ThankYouFormStyled>
      <Text>
        <img src={wichtel} alt="Wichtel" width={90}/>
      </Text>
      <Headline>Welcome to our NKT Secret Santa Village!</Headline>
      <Text>
        24 elves are at home here but they lack one thing: Electricity. You can help to power up the wind turbines in the village and supply the elves' huts with electricity by answering one question each day from <span style={{whiteSpace: 'nowrap'}}>December 1-24</span>.
      </Text>
      <Text>
        Of course, there is also something to win: Amazon vouchers worth 100 euros will be raffled off among all participants during the each December weekend. If you like to participate, please leave your email address with us in the assigned box.
      </Text>
      <Text>
        You can read all the conditions here: <br/><TextLink href="https://www.nkt.com/terms-of-use-xMas" target="_blank">Conditions of participation</TextLink>.
      </Text>
      <Text>
        We wish you lots of fun and a happy Advent season!
      </Text>
      <ButtonItem>
        <Button onClick={() => onClose()}>Close</Button>
      </ButtonItem>
    </ThankYouFormStyled>
  );
}

export default IntroScreen;
