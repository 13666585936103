/// <reference types="vite-plugin-svgr/client" />
import styled from "styled-components";
import media from "../../utilities/media";
import mazes from "./mazes";
import Srand from 'seeded-rand';

const MazeStyled = styled.div`
  width: 100%;
  
  svg {
    width: 100%;
    height: auto;
    .st0 {
      transition: fill 0.3s ease-in-out;
    }
  }
  ${props => props.status === 1 && `
    ${props.selected} .st0 {
      fill: var(--color-highlight);
    }
  `}

  ${props => props.status === 2 && `
    ${props.selected} .st0 {
      fill: var(--color-error);
    }
  `}
`;

const MazeDesktop = styled.div`
  display: none;
  
  ${media.moreThan("lg")} {
    display: block;
  }
`;

const MazeMobile = styled.div`
  ${media.moreThan("lg")} {
    display: none;
  }
`;

const Maze = ({answer=1, selected=0, day}) => {
  let status = 0;
  if (selected) {
    status = answer === selected ? 1 : 2;
  }

  const rnd = new Srand(day * 999 + answer);
  const maze = mazes[answer][rnd.intInRange(0, mazes[answer].length - 1)];

  const Desktop = maze['Desktop'];
  const Mobile = maze['Mobile'];

  return (
    <MazeStyled status={status} selected={`.pfad-${selected}`}>
      <MazeDesktop>
        <Desktop />
      </MazeDesktop>
      <MazeMobile>
        <Mobile />
      </MazeMobile>
    </MazeStyled>
  );
}

export default Maze;
